import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'

import Title from '../components/page-title'
//import SubTitle from "../components/page-subtitle";
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
//import PageVideo from '../components/page-video';
import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'

import Image1 from '../images/designs/happy-haus-hero-9.jpg'
import Image2 from '../images/designs/happy-haus-holland-park-west-14.jpg'
import Image3 from '../images/designs/happy-haus-helensvale-10.jpg'
import Image4 from '../images/designs/happy_haus_darlington_047.jpg'
import { Link } from 'gatsby'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]
const mapDispatchToProps = (dispatch) => ({})

const PortraitImage1 = 'designs/illustration-bardon.jpg'
const PortraitImage2 =
  'designs/happyhaus-gallery-2021-09-10-photo-annerley-JG-1050050.jpg'
const PortraitImage3 = 'designs/happyhaus-gallery-photo-dewar-AM-2.jpg'
const PortraitImage4 =
  'designs/happyhaus-gallery-2021-03-12-photo-red-beach-JG-1120985-2.jpg'
const LandscapeImage1 =
  'designs/happyhaus-gallery-photo-ocean-shores-JG-1090042.jpg'
const LandscapeImage2 =
  'designs/happyhaus-gallery-photo-ocean-shores-JG-1090271.jpg'

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'knockdown':
        dispatch(
          setLightboxForm({
            category: 'Guide Knockdown Rebuild',
            title: 'Get your free guide<br />to knockdown and rebuild',
            redirect: '/build-guides-thank-you',
          })
        )
        break

      case 'new':
        dispatch(
          setLightboxForm({
            category: 'Build Guide New',
            title: 'Get your free guide<br />to building your new home',
            redirect: '/build-guides-thank-you',
          })
        )
        break

      default:
        break
    }
  }

  render() {
    return (
      <>
        <SEO
          title="Custom Designs"
          description="You and your family can have a home as individual as the lives
                  you lead and the people you are."
          images={ogImages}
        />

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 25]}>
              <Title grey>Custom Haus</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy>
                <p>
                  In partnership with our in-haus design + build teams, Happy
                  Haus will guide you through the custom home journey.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row noPad={true}>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage1} ratio={9 / 16} />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 25]}>
              <Title grey>Why Custom</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <Copy>
                <p>
                  Our considered approach guides a haus along the right path to
                  becoming your home. In some instances, the right home is a
                  custom design. Some clients may be wary of the potential
                  challenges associated with custom-designed homes, however our
                  process ensures it is still a Happy Haus.
                </p>
                <p>
                  While the design process may be a little more involved
                  upfront, the same principles and stages of design are applied
                  to the project to make this a seamless design + build
                  experience. Typically, we are engaged on custom projects for
                  two main reasons:
                </p>
                <ol>
                  <li>
                    Our clients have a block that is unique in one or many
                    aspects
                  </li>
                  <li>
                    Our clients have a brief for their home that has unique
                    aspects.
                  </li>
                </ol>
                <p>
                  Some of our all-time, favourite projects have come from the
                  custom haus process. The partnership with clients is highly
                  rewarding as we work together to realise our clients'
                  aspirations for a Happy Haus life.
                </p>
                <br />
                <Title>
                  Considering a custom home?{' '}
                  <Link href="/contact">
                    <a style={{ color: '#888888' }}>Contact us</a>
                  </Link>
                </Title>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap="wrap" flexDirection="row">
            <Box width={[1, 1 / 2]} px={2}>
              <Image src={PortraitImage1} alt="" />
            </Box>
            <Box width={[1, 1 / 2]} px={2} mr={[0, 0]}>
              <Image src={PortraitImage2} alt="" />
            </Box>
          </Flex>
        </Row>
        <Row>
          <Flex flexWrap="wrap" flexDirection="row">
            <Box width={[1, 1 / 2]} px={2}>
              <Image src={PortraitImage3} alt="" />
            </Box>
            <Box width={[1, 1 / 2]} px={2} mr={[0, 0]}>
              <Image src={PortraitImage4} alt="" />
            </Box>
          </Flex>
        </Row>
        <Row noPad={true}>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage2} ratio={9 / 16} />
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
